import React, { useContext, useEffect } from "react";
import {
  GetCombinedReport_combinedReport,
  GetCombinedReport_combinedReport_atomicData,
} from "./types/GetCombinedReport";
import styles from "./CombinedReport.module.css";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import AtomicSignificanceButtons from "./AtomicSignificanceButtons";
import DialogBoxPassword from "../DialogBox/DialogBoxPassword";
import authService from "../../services/authService";
import { AddAtomicStatus } from "./types/AddAtomicStatus";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { ApproveAtomic, ApproveAtomicVariables } from "./types/ApproveAtomic";
import ApproveAtomicMutation from "./ApproveAtomicMutation";
import addAtomicStatus from "../CombinedReport/AddAtomicStatusQuery";
import CommentIcon from "@mui/icons-material/Comment";
import { CumulativeContext } from "../../context/CumulativeContext";
import { cloneDeep } from "lodash";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import * as Constants from "../../constants";
import AppContext from "../../context/AppContext";
import { CLINCAL_TRIAL_USER_TYPE } from "../../constants";
import ReviewButton from "./ReviewButton";
import { updatePatientepisodesAbnormalCache } from "../../utils/updateApolloCache";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

type ResultsCardProps = {
  episode: GetCombinedReport_combinedReport;
  isTextReport: boolean;
  patientId: number;
  allPatients: boolean;
  sortBy?: string | null;
};

const isOffline = (episode: GetCombinedReport_combinedReport) => {
  if (episode.csPanelApprovalStatus === 8) {
    return true;
  }

  return false;
};

const ResultsTable: React.FC<ResultsCardProps> = (props) => {
  const client = useApolloClient();
  const { episode, isTextReport, allPatients, patientId, sortBy } = props;
  const { setSuccess } = useContext(AppContext);
  const {
    setIsCommentsOpen,
    setIsPlainComment,
    setCurrentAtomic,
    setCurrentCsStatus,
    atomics,
    setAtomics,
    setIsAtomicLoading,
    random,
    setRandom,
    setCurrentReportId,
    setShowCommentsExtra,
    repeatAtomics,
    setRepeatAtomics,
  } = useContext(CumulativeContext);

  //mutations and Queries
  const [approveAtomicMutation, { data: approveAtomicData, loading: approveAtomicLoading, error: approveAtomicError }] =
    useMutation<ApproveAtomic, ApproveAtomicVariables>(ApproveAtomicMutation);

  const [
    addAtomicStatusQuery,
    { data: addAtomicStatusData, loading: addAtomicStatusLoading, error: addAtomicStatusError },
  ] = useLazyQuery<AddAtomicStatus>(addAtomicStatus, {
    fetchPolicy: "no-cache",
  });

  //toggle atomic loading spinner
  useEffect(() => {
    setIsAtomicLoading(addAtomicStatusLoading || approveAtomicLoading);
  }, [addAtomicStatusLoading, approveAtomicLoading]);

  //resolving Add Atomic
  useEffect(() => {
    if (addAtomicStatusData && addAtomicStatusData.addAtomicStatus) {
      const result = addAtomicStatusData.addAtomicStatus[0];
      const newAtomics = cloneDeep(atomics);
      if (result) {
        newAtomics.forEach((newAtomic) => {
          newAtomic?.atomicData?.forEach((a) => {
            if (a?.atomicId === result.atomicId) {
              a.csStatus = result.csStatus || 0;
              a.csName = result.csName;
              a.csUserId = result.csUserId;
              a.csDatetime = result.csDatetime;
            }

            if (newAtomic?.reportId === episode.reportId && a?.atomicId === null) {
              a.atomicId = result.atomicId;
              a.csStatus = result.csStatus || 0;
              a.csName = result.csName;
              a.csUserId = result.csUserId;
              a.csDatetime = result.csDatetime;
            }
          });
        });
        setAtomics(newAtomics);
      }
    }
  }, [addAtomicStatusData]);

  //resolving Approve Atomic
  useEffect(() => {
    const approveResult = approveAtomicData?.approveAtomic[0];
    if (approveResult) {
      const newAtomics = cloneDeep(atomics);

      let isClearEpisodeStatus = false;

      newAtomics.forEach((newAtomic) => {
        if (newAtomic) {
          let isClearApproval = false;
          let foundThis = false;
          newAtomic.atomicData?.forEach((a) => {
            if (a && a.atomicId === approveResult.atomicId) {
              if (a.csApproval !== Boolean(approveResult.approval)) {
                isClearEpisodeStatus = true;
                foundThis = true;
                if (!a.csApproval) {
                  isClearApproval = true;
                }
              }
              a.csApproval = Boolean(approveResult.approval);
              a.csApproverUserId = approveResult.approverUserId;
              a.csApproverUsername = approveResult.approverName;
              a.csApproverDatetime = approveResult.approverDatetime;
            }
          });
          if (foundThis) {
            if (isClearApproval && newAtomic.csPanelApprovalStatus === Constants.APPROVAL_STATUS.Printed) {
              newAtomic.csPanelApprovalStatus = Constants.APPROVAL_STATUS.Approved;
            }
          }
        }
      });
      if (isClearEpisodeStatus) {
        newAtomics.forEach((newAtomic) => {
          if (newAtomic) {
            // this will trigger status recalculation
            newAtomic.status = "";
          }
        });
      }

      setAtomics(newAtomics);
    }
  }, [approveAtomicData]);

  const onUpdateAbnormal = (args: any) => {
    const { reportId } = args;

    console.log("reportid - ", reportId);

    updatePatientepisodesAbnormalCache(client, reportId, {
      patientId: patientId,
      providers: authService.getProviders(),
      allPatients: allPatients,
      sortBy: sortBy,
      showRemovedPanels: authService.getShowDeletedPanels(),
    });
  };

  const addAtomicStatusHandler = (atomic: GetCombinedReport_combinedReport_atomicData, csStatus: number) => {
    addAtomicStatusQuery({
      variables: {
        atomicId: atomic.atomicId ? atomic.atomicId : 0,
        csStatus: csStatus,
        csUserId: Number(authService.getUserId()),
        reportId: episode.reportId,
      },
    });
  };

  const atomicSignificanceButtonsClickHandler = (args: any) => {
    const { atomic, status } = args;
    setCurrentAtomic(atomic);
    setCurrentCsStatus(status);

    if (atomic.csStatus === null) {
      addAtomicStatusHandler(atomic, status);
    } else {
      if (status !== atomic.csStatus) {
        setShowCommentsExtra(true);
        setIsCommentsOpen(true);
      }
    }
  };

  const approveAtomicHandler = (atomic: GetCombinedReport_combinedReport_atomicData, approval: boolean) => {
    approveAtomicMutation({
      variables: {
        input: {
          atomicId: atomic?.atomicId || 0,
          approval: approval,
          approverUserId: Number(authService.getUserId()),
        },
      },
    });
  };

  const addCommentClickHandler = (atomic: GetCombinedReport_combinedReport_atomicData) => {
    setRandom(random + 1);
    setCurrentReportId(episode.reportId);
    setCurrentAtomic(atomic);
    setIsPlainComment(true);
    setShowCommentsExtra(true);
    setIsCommentsOpen(true);
  };

  const handleRepeatCommentClickHandler = (atomic: GetCombinedReport_combinedReport_atomicData) => {
    setRandom(random + 1);
    setCurrentReportId(episode.reportId);
    setCurrentAtomic(atomic);
    setIsPlainComment(true);
    setShowCommentsExtra(false);
    setIsCommentsOpen(true);
    // handleRepeatClickHandler(atomic);
  };

  const handleRepeatClickHandler = (atomic: any) => {
    if (atomic.atomicId) {
      const newRepeatAtomics = [...repeatAtomics, atomic];
      console.log(atomic);
      console.log(episode);
      atomic.reportTestName = episode.reportTestName;
      setRepeatAtomics(Array.from(new Set(newRepeatAtomics)));
      setSuccess(`${episode.reportTestName} - ${atomic.name} added to basket.`);
    }
  };

  //handle errors
  if (approveAtomicError || addAtomicStatusError) {
    return <ErrorPopover />;
  }

  return (
    <TableContainer style={{ marginTop: "20px" }}>
      <Table aria-label="simple table">
        {episode.atomicData && episode.atomicData[0] && episode.atomicData[0]["name"] != null ? (
          <TableHead>
            <TableRow>
              <TableCell className={styles.headertblFirst} width={isTextReport ? "50%" : "20%"}>
                Item
              </TableCell>
              {!isTextReport && (
                <>
                  <TableCell className={styles.headertbl} width={"10%"}>
                    Result
                  </TableCell>
                  <TableCell className={styles.headertbl} width={"10%"}>
                    Unit
                  </TableCell>
                  <TableCell className={styles.headertbl} width={"10%"}>
                    Reference Range
                  </TableCell>
                </>
              )}
              <TableCell className={styles.headertbl} width={"13%"}>
                Clinical Significance
              </TableCell>
              <TableCell className={styles.headertbl} width={"30%"}>
                Actions
              </TableCell>
              <TableCell className={styles.headertbl} width={"6%"}></TableCell>
            </TableRow>
          </TableHead>
        ) : (
          ""
        )}
        <TableBody>
          {episode.atomicData
            ? episode.atomicData.map((atomic: GetCombinedReport_combinedReport_atomicData | null) => {
                if (!!atomic && !atomic.commentFlag)
                  return (
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    <TableRow className={styles.striped} key={atomic.atomicId}>
                      <TableCell component="th" scope="row" className={styles.tableboxFirst}>
                        {atomic.name}
                      </TableCell>
                      {!isTextReport && (
                        <>
                          <TableCell className={atomic.abnormal ? styles.tableboxAbnormal : styles.tablebox}>
                            <span
                              // className={styles.pdfprint}
                              dangerouslySetInnerHTML={{
                                __html: `${atomic.value || "-"} ${atomic.abnormal ? " *" : ""}`,
                              }}
                            ></span>
                          </TableCell>
                          <TableCell className={styles.tablebox}>{atomic.measurement}</TableCell>
                          <TableCell className={styles.tablebox}>{atomic.range}</TableCell>
                        </>
                      )}
                      <TableCell className={styles.tablebox}>
                        {atomic.abnormal && (
                          <AtomicSignificanceButtons
                            atomic={atomic}
                            onAtomicSignificanceButtonClick={atomicSignificanceButtonsClickHandler}
                            isOffline={isOffline(episode)}
                          />
                        )}
                        {!atomic.abnormal && atomic.value_type === "FT" && (
                          <ReviewButton atomic={atomic} onReviewButtonClick={onUpdateAbnormal} />
                        )}
                      </TableCell>
                      <TableCell className={styles.tablebox}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          {atomic.abnormal &&
                            atomic.csStatus !== null &&
                            authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Approver && (
                              <>
                                {!atomic.csApproval ? (
                                  <DialogBoxPassword
                                    onLoginSuccess={() => approveAtomicHandler(atomic, true)}
                                    buttonStyle="approve"
                                    isApprove={true}
                                    isOffline={isOffline(episode)}
                                  />
                                ) : (
                                  <>
                                    <Typography className={styles.approverText}>
                                      {atomic.csApproverUsername}
                                      <br />
                                      {dayjs.utc(atomic.csApproverDatetime).local().format("DD MMM YYYY HH:mm")}
                                    </Typography>
                                    <DialogBoxPassword
                                      onLoginSuccess={() => approveAtomicHandler(atomic, false)}
                                      buttonText="Clear"
                                      buttonStyle="clear"
                                      isApprove={false}
                                      isOffline={isOffline(episode)}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          {atomic.abnormal &&
                            authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Approver &&
                            !isOffline(episode) && (
                              <Button
                                onClick={
                                  authService.getRepeatAtomicComment()
                                    ? () => {
                                        handleRepeatCommentClickHandler(atomic);
                                      }
                                    : () => {
                                        handleRepeatClickHandler(atomic);
                                      }
                                }
                                className={styles.atomicRequestRepeatButton}
                              >
                                Repeat
                              </Button>
                            )}
                          {atomic.csStatus !== null &&
                            authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Viewer && (
                              <>
                                {!atomic.csApproval && (
                                  <Typography className={styles.approverText}>Not approved</Typography>
                                )}
                                {atomic.csApproval && (
                                  <Typography className={styles.approverText}>
                                    {atomic.csApproverUsername}
                                    <br />
                                    {dayjs.utc(atomic.csApproverDatetime).local().format("DD MMM YYYY HH:mm")}
                                  </Typography>
                                )}
                              </>
                            )}
                        </Box>
                      </TableCell>
                      <TableCell align="right" className={styles.tablebox}>
                        {
                          <CommentIcon
                            className={
                              atomic.csComment
                                ? atomic.dCF || atomic.actionRequired || atomic.noActionRequired
                                  ? styles.commentCallToAction
                                  : styles.commentYes
                                : styles.commentNo
                            }
                            onClick={() => {
                              addCommentClickHandler(atomic);
                            }}
                          />
                        }
                      </TableCell>
                    </TableRow>
                  );
                else if (atomic?.commentFlag)
                  return (
                    <TableRow className={styles.striped} key={atomic.atomicId}>
                      <TableCell colSpan={6} component="th" scope="row" className={styles.tableboxFirst}>
                        <span
                          // className={styles.pdfprint}
                          dangerouslySetInnerHTML={{
                            __html: atomic.value || "-",
                          }}
                        ></span>
                      </TableCell>
                      <TableCell align="right" className={styles.tablebox}>
                        {
                          <CommentIcon
                            className={
                              atomic.csComment
                                ? atomic.dCF || atomic.actionRequired || atomic.noActionRequired
                                  ? styles.commentCallToAction
                                  : styles.commentYes
                                : styles.commentNo
                            }
                            onClick={() => {
                              addCommentClickHandler(atomic);
                            }}
                          />
                        }
                      </TableCell>
                    </TableRow>
                  );
                else return null;
              })
            : ""}
          {/* {episode.atomicData
            ? episode.atomicData
                .filter((f: GetCombinedReport_combinedReport_atomicData | null) => f?.commentFlag)
                .map((atomic: GetCombinedReport_combinedReport_atomicData | null) => {
                  if (!!atomic)
                    return (
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      
                    );
                  else return null;
                })
            : ""} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultsTable;
