import React, { useCallback, useContext } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation } from "@apollo/client";
import { GenericResolve_fetchDiscoverableUsers } from "./types/GenericResolve";
import GenericMutation from "./GetDiscoverableUsersQuery";
import ShareReportMutation from "./ShareReportMutation";
import debounce from "lodash/debounce";
import { useDialogStyles } from "./styles/dialogStyles";
import { LoadingButton } from "@mui/lab";
import AppContext from "../../context/AppContext";
interface DialogBoxShareEmailProps {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  setOpenShareEmail: (value: boolean) => void;
  reports: Record<string, boolean>;
}

const useStyles = useDialogStyles;
const DialogResultsUserShare: React.FC<DialogBoxShareEmailProps> = (props) => {
  const onClose = () => {
    setPage(1);
    props.onClose();
  };
  const classes = useStyles();
  const { setSuccess, setError } = useContext(AppContext);

  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = React.useState("");

  const [selectedUser, setSelectedUser] = React.useState<{
    name: string;
    address: string;
    providerNumber: string;
  } | null>(null);
  const [message, setMessage] = React.useState<string>("");
  const [searchResults, setSearchResults] = React.useState<any>([]);

  const [serachDoctorReq, { loading: dataLoading }] =
    useMutation<GenericResolve_fetchDiscoverableUsers>(GenericMutation);

  const [shareReport, { loading: loadShare }] = useMutation(ShareReportMutation);

  const debouncedSearch = useCallback(
    debounce(async (searchText: string) => {
      if (searchText) {
        const { data } = await serachDoctorReq({
          variables: {
            name: searchText.toUpperCase() || null,
          },
        });
        if (data?.fetchDiscoverableUsers) {
          const transformedResults = data.fetchDiscoverableUsers.fetchDiscoverableUsers.map((doctor, index) => ({
            id: index,
            name: doctor.fullname || "",
            address: doctor.address || "",
            providerNumber: doctor.providerno || "",
          }));
          setSearchResults(transformedResults);
        }
      } else {
        setSearchResults([]);
      }
    }, 500), // 500ms delay
    [], // Empty dependency array since we don't want to recreate this function
  );

  const serachDoctor = (searchText: string) => {
    setSearchText(searchText);
    debouncedSearch(searchText);
  };
  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  // Columns for DataGrid
  const columns = [
    {
      field: "combined",
      flex: 1,
      renderCell: (params: any) => (
        <Box
          sx={{
            width: "100%",
            py: 1, // Add some vertical padding
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>{params.row.name}</Typography>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: "0.875rem",
            }}
          >
            {params.row.address}
          </Typography>
        </Box>
      ),
    },
  ];
  // Filtered rows based on search
  const filteredResults = searchResults.filter(
    (row) => searchText.trim() !== "" && row.name.toLowerCase().includes(searchText.toLowerCase()),
  );
  return (
    <>
      <Dialog
        open={props.open}
        onClose={onClose}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogBox }}
      >
        <DialogTitle>
          <DialogBoxTitle
            title={page === 1 ? "Search User" : "Confirm Message"}
            width={200}
            closeButton
            handleClose={onClose}
          />
        </DialogTitle>

        <DialogContent>
          {page == 1 && (
            <Box sx={{ marginBottom: "0" }}>
              <TextField
                className={classes.searchInput}
                label="Search by Name"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={(e) => serachDoctor(e.target.value)}
              />
              <div className={classes.dataGridContainer}>
                <DataGrid
                  rows={filteredResults}
                  columns={columns}
                  hideFooter
                  loading={dataLoading}
                  onRowClick={(params) => {
                    const user = searchResults.find((row) => row.id === params.row.id);
                    setSelectedUser(user || null);
                    setPage(2); // Move to the next page
                  }}
                  disableColumnMenu
                  slots={{
                    columnHeaders: () => null,
                  }}
                  sx={{
                    border: "none", // Remove outer border
                    "& .MuiDataGrid-cell": {
                      border: "none", // Remove cell borders
                    },
                    "& .MuiDataGrid-row": {
                      borderBottom: "1px solid rgba(224, 224, 224, 1)", // Add divider between rows
                    },
                    "& .MuiDataGrid-columnSeparator": {
                      display: "none", // Remove column separators
                    },
                    "&:hover": {
                      cursor: "pointer", // Change cursor to pointer on hover
                    },
                  }}
                />
              </div>
            </Box>
          )}{" "}
          {page == 2 && (
            <Box>
              <Typography variant="body1" className={classes.header}>
                Complete Message
              </Typography>
              <Box></Box>
              <Box>
                <TextField
                  margin="dense"
                  id="Email"
                  name="Email"
                  label="Message: (Optional)"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  onChange={(e: any) => setMessage(e.target.value)}
                  aria-label="Message"
                />
              </Box>
            </Box>
          )}
          {page == 3 && (
            <Box>
              <Typography variant="h6">Confirm & Send</Typography>
              <Typography variant="body2" paragraph>
                By clicking ‘Send’ you intend to share the selected results to the following eResults user:
              </Typography>
              <Typography variant="subtitle1">{selectedUser?.name}</Typography>
              <Typography variant="subtitle2">{selectedUser?.address}</Typography>
              <Typography variant="body2" paragraph>
                <strong>Message:</strong> {message || "No message provided"}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Disclaimer: XYZ
              </Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => {
              if (page != 1) {
                setPage(page - 1);
              } else {
                props.onBack();
              }
            }}
            variant="contained"
            color="secondary"
          >
            Back
          </Button>
          {page !== 1 && (
            <LoadingButton
              onClick={() => {
                if (page === 3) {
                  shareReport({
                    variables: {
                      input: {
                        providerno: selectedUser?.providerNumber,
                        comment: message,
                        reportIds: Object.keys(props.reports)
                          .filter((key) => props.reports[key])
                          .map((key) => Number(key)),
                      },
                    },
                  })
                    .catch((e) => {
                      setError(e.message);
                    })
                    .finally(() => {
                      setSuccess("Report shared successfully");
                      onClose();
                      setPage(1);
                    });
                } else {
                  setPage(page + 1);
                }
              }}
              variant="contained"
              color="primary"
              loading={loadShare}
              disabled={loadShare}
            >
              {page === 3 ? "Send" : "Next"}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogResultsUserShare;
