import React, { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Typography } from "@mui/material";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import { SendReportViaEmailVariables, SendReportViaEmail } from "../PanelReports/types/SendReportViaEmail";
import shareEmailReportMutation from "../PanelReports/PatientReportEmailMutation";
import { useMutation } from "@apollo/client";
import { useDialogStyles } from "./styles/dialogStyles";
import AppContext from "../../context/AppContext";
interface DialogBoxShareEmailProps {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  reportsToSende: {
    selected?: Record<string, boolean>;
    patient?: number;
    labno?: string;
  };
}

const useStyles = useDialogStyles;

const DialogBoxShareEmail: React.FC<DialogBoxShareEmailProps> = (props) => {
  const classes = useStyles();
  const [sendEmail, { loading }] = useMutation<SendReportViaEmail, SendReportViaEmailVariables>(
    shareEmailReportMutation,
  );
  const { setSuccess } = useContext(AppContext);
  const [page, setPage] = React.useState(1);
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const onClose = () => {
    setPage(1);
    props.onClose();
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={onClose}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogBox }}
      >
        <DialogTitle>
          <DialogBoxTitle
            title={page === 1 ? "Complete Details" : "Confirm & Send"}
            width={200}
            closeButton
            handleClose={onClose}
          />
        </DialogTitle>

        <DialogContent>
          {page === 1 ? (
            <>
              <Box>
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                  value={email}
                />
              </Box>
              <Box>
                <TextField
                  autoFocus
                  margin="dense"
                  id="message"
                  label="Message (Optional)"
                  name="message"
                  type="text"
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setMessage(event.target.value);
                  }}
                  multiline
                  minRows={4}
                  value={message}
                />
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Typography>
                  By clicking ‘Send’ you intend to email the selected results to the email address below.
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography>Email: {email}</Typography>
              </Box>
              <Box mt={3} mb={2}>
                <Typography>Disclaimer: XXXXXXXX</Typography>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          {/* <Box display="flex" justifyContent="space-between"> */}
          <Button
            onClick={() => {
              if (page === 1) {
                props.onBack();
              } else {
                setPage(page - 1);
              }
            }}
            variant="contained"
            color="secondary"
          >
            Back
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              if (page === 2) {
                sendEmail({
                  variables: {
                    emailAddress: email,
                    labnoDigitOnly: props.reportsToSende.labno,
                    patientId: parseInt(String(props.reportsToSende.patient || "0")) || 0,
                    reportIds: Object.keys(props.reportsToSende.selected ?? {}).map((key) => parseInt(key)),
                    message: message,
                    // message:""
                  },
                }).then(() => {
                  setSuccess(`Reports has been shared to ${email}`);
                  onClose();
                });
              } else {
                if (email.trim() !== "" && isValidEmail(email)) {
                  setPage(page + 1);
                } else {
                  // Show email validation error
                }
              }
            }}
            variant="contained"
            color="primary"
          >
            {page === 1 ? "Next" : "Send"}
          </Button>
          {/* </Box> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogBoxShareEmail;
